import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/layout";
import SchemaTable from "../components/schemaTable/SchemaTable";
import ParamsTable from "../components/paramsTable/ParamsTable";
import dets from "../../dets.js";
import Uris from "../components/uris/Uris";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="gStop" mdxType="Seo" />
    <h1>{`GStop`}</h1>
    <p>{`Extension of the data from GTFS stops.txt file. It contains everything from that file plus information about bus routes associated with the stops.`}</p>
    <section>
      <h2 id="g-stop-schema">GStop Schema</h2>
      <SchemaTable dets={dets.gStop} mdxType="SchemaTable" />
      <h2 id={dets.gStop.routes[0].id}>{dets.gStop.routes[0].title}</h2>
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstop/bystopid
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "stops": [
      {
        "stop_id": "84605257301",
        "stop_name": "Seamus Quirke Road, stop 525731",
        "stop_lat": 53.274450938559,
        "stop_lon": -9.07946923657427,
        "g_routes_data": [
          {
            "route_short_name": "404",
            "direction_id": "0",
            "typical_stop_sequence_on_route": 33
          },
          {
            "route_short_name": "405",
            "direction_id": "0",
            "typical_stop_sequence_on_route": 5
          }
        ],
        "g_routes": ["404", "405"]
      }
      // ... 347 more GStops
    ]
  }
}
`}</code></pre>
  </details>
    </section>
    <section>
      <h2 id={dets.gStop.routes[1].id}>{dets.gStop.routes[1].title}</h2>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.gStop.routes[1].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstop/bystopid/8460B5228201,8460B5230101
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "stops": [
      {
        "stop_id": "8460B5228201",
        "stop_name": "Merlin Park Hospital, stop 522821",
        "stop_lat": 53.2772418198183,
        "stop_lon": -8.99416106084897,
        "g_routes_data": [
          {
            "route_short_name": "402",
            "direction_id": "0",
            "typical_stop_sequence_on_route": 42
          },
          {
            "route_short_name": "402",
            "direction_id": "1",
            "typical_stop_sequence_on_route": 1
          }
        ],
        "g_routes": ["402"]
      },
      {
        "stop_id": "8460B5230101",
        "stop_name": "Eyre Square, stop 523011",
        "stop_lat": 53.2747593973541,
        "stop_lon": -9.04893497271275,
        "g_routes_data": [
          {
            "route_short_name": "402",
            "direction_id": "1",
            "typical_stop_sequence_on_route": 20
          },
          {
            "route_short_name": "407",
            "direction_id": "0",
            "typical_stop_sequence_on_route": 1
          }
        ],
        "g_routes": ["402", "407"]
      }
    ]
  }
}
`}</code></pre>
  </details>
    </section>
    <section className="minHeightSection">
      <h2 id={dets.gStop.routes[2].id}>{dets.gStop.routes[2].title}</h2>
      <h3>{`Params`}</h3>
      <ParamsTable params={dets.gStop.routes[2].params} mdxType="ParamsTable" />
      <h3>{`Example`}</h3>
      <h4>{`Request`}</h4>
      <pre><code parentName="pre" {...{}}>{`/api/gstop/byrouteid/401/0
`}</code></pre>
  <details>
    <summary>
    <h4>Response</h4>
    </summary>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "results": {
    "routes": [
      [
        {
          "stop_id": "8460B6355601",
          "stop_name": "Galway Tech Park, stop 635561",
          "stop_lat": 53.29902494788,
          "stop_lon": -8.98686582677608,
          "g_routes_data": [
            {
              "route_short_name": "401",
              "direction_id": "0",
              "typical_stop_sequence_on_route": 1
            },
            {
              "route_short_name": "409",
              "direction_id": "1",
              "typical_stop_sequence_on_route": 1
            }
          ],
          "g_routes": ["401", "409"]
        }
        // ... more GStops
      ]
    ]
  }
}
`}</code></pre>
  </details>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      